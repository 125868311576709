import React, { Component } from 'react';
import { Row, Container, Col} from 'reactstrap';
import { HashLink as Link } from "react-router-hash-link";
import { NavMenu } from './NavMenu';

import ins1image from '../images/Ins-1.jpg';
import ins2image from '../images/Ins-2.jpg';
import ins3image from '../images/Ins-3.jpg';
import ins4image from '../images/Ins-4.jpg';
import ReactGA from 'react-ga';
import '../css/custom.css'
import { SignUp } from './SignUp';
ReactGA.initialize('UA-251143020-1');


export class Home extends Component {
  componentDidMount() {
    document.title = "Your adventure starts with Hütte"
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <div id="holidayPage">
        <div className="headerImage_xl" id="holidaymakerHeader">
          <Container fluid >
            <header>
              <Row id="headerIconRow">
                <Col xs={{ size: 2, offset: 1 }} md={{ size: 1, offset: 1 }}>
                  <div className="logo"></div>
                </Col>

                <Col id="socialMediaCol"
                  xs={{ size: 4, offset: 5 }}
                  sm={{ size: 6, offset: 3 }}
                  md={{ size: 5, offset: 5 }}
                  lg={{ size: 4, offset: 6 }}
                  xl={{ size: 3, offset: 7 }}
                >
                  <Row>
                    <Col
                      xs={{ size: 4 }}
                      sm={{ size: 2 }}
                      md={{ size: 2 }}
                      lg={{ size: 2 }}>
                      <div className="facebook">
                        <a href="https://www.facebook.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>
                      </div>
                    </Col>
                    <Col
                      xs={{ size: 4 }}
                      sm={{ size: 2 }}
                      md={{ size: 2 }}
                      lg={{ size: 2 }}>
                      <div className="instagram">
                        <a href="https://www.instagram.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>
                      </div>
                    </Col>
                    <Col sm="4">
                      <div id="btnSignUp">
                        <Link smooth to="/page#signUpJump">
                          SIGN UP
                      </Link>
                      </div>

                    </Col>
                  </Row>
                </Col>


              </Row>
              <Row>
                <Col md={{ size: 8, offset: 2 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                  <div id="headerText">
                    <p>Your adventure </p>
                    <p>starts with Hütte</p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={{size:8, offset:1}} sm={{ size: 6, offset: 1 }}  md={{ size: 5, offset: 2 }}   lg={{ size: 3, offset: 2 }} xl={{ size: 2, offset: 2 }} >
                  <div id="headerText_button">
                    <Link smooth to="/page#signUpJump">
                      <span className="jumptext">SIGN UP TO HÜTTE</span>
                    </Link>
                  </div>
                </Col>
              </Row>

            </header>
          </Container>
        </div>

        <Container fluid>
          <div className="homeBody">
            <Row>
              <Col lg={{ size: 4, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <h1>We are Hütte
          </h1>
                <p>
                  Drawing on a lifelong alpine love affair, we help shape unforgettable holidays tailored to you. Think of us as your digital front desk. Ding! We've already procured the finest accommodation on the hill. We'll also help you with transfers, ski hire, activities and even the best places to eat out.
                </p>
                <p>
                  As avid mountain adventurers ourselves, we wanted to provide a simple platform to book alpine holidays securely and with ease. With flexible dates and year-round options, with Hütte, you can also experience the slopes from a fresh (and warmer!) perspective - we highly recommend it! Summer to winter, slopeside or off-piste - we'll take the hassle out of booking, leaving you free to dream the adventure.
            </p>
                <div id="informationTitleButton">
                  <Link smooth to="/page#signUpJump">
                    <span className="jumptext"> FIND OUT MORE</span>
                  </Link>
                </div>
              </Col>
              <Col lg={{ size: 5, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <div id="informationTitleImage"></div></Col>
            </Row>

            <Row id="homeMiddleGreen">
              <Col xs="12" sm={{ size: 4, offset: 1 }}>

                <h1>
                  <span id="signUpDiv" className="bottomOrange">
                    Sign up </span>
             for {"\n"}
                  <div>Hütte updates</div>
                </h1>
                <p>
                Sign up to be first to know about the launch of Hütte. Let us help plan your next memorable holiday, minus the hassle. Join our growing community! Be part of a new wave in mountain hospitality!
            </p>
              </Col>
              <Col xs="12" sm={{ size: 5, offset: 1 }}>
              <SignUp groupType="HolidayMaker" />
              </Col>
            </Row>
            <Row id="instagramHeadImage">
              <Col xs="12">
                <div id="instagramHeadImageHeight"> </div>
              </Col>
            </Row>



            <Row>
              <Col xs="12" sm="1" xl="1">
              </Col>
              <Col xs="12" sm="10" xl="10">

                <Row id="instagramRowTop">
                  <Col sm="12" xs={{ size: 10, offset: 1 }}>
                    <h1>
                      <span id="signUpDiv" className="bottomOrange">
                       <a href="https://www.instagram.com/HutteTravel" rel="noopener noreferrer" target="_blank"> Follow </a> </span><span> Hütte on Instragram for updates</span>
                    </h1>
                  </Col>
                </Row>
                <Row className="instagramRow">
                  <Col xs="12" xl="3" sm="6">
                    <img src={ins1image} className="imageCenter" alt="" />
                  </Col>

                  <Col xs="12" xl="3" sm="6">
                    <img src={ins2image} className="imageCenter" alt="" />
                  </Col>
                  <Col xs="12" xl="3" sm="6">

                    <img src={ins3image} className="imageCenter" alt="" />
                  </Col>

                  <Col xs="12" xl="3" sm="6">
                    <img src={ins4image} className="imageCenter" alt="" />
                  </Col>
                </Row>
              </Col>
              <Col xs="12" sm="1" xl="1">
              </Col>
            </Row>


            <Row id="bottomSignupRow">
              <Col xs="12" md="6" id="bottomSignup">
                <Row>
                  <Col xs="12" lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                    <h1>
                      <div id="signUpJump">
                        <span id="signUpDiv" className="bottomOrange">
                          Sign up  </span><span> for updates</span>
                      </div>
                    </h1>
                    <p id="bottomtext">
                      Join Our Community!
                      Sign up to catch our latest news. We'll keep you in the loop regarding promotions, events and our launch.

                </p>
                    <div className="bottomsignupdiv">
                    <SignUp groupType="HolidayMaker" />
                    </div>
                  </Col>
                </Row>


              </Col>

              <Col xs="12" md="6" id="bottomPicture">
              </Col>
            </Row>



            <Row id="footer">
              <Col>
                <Row>

                  <Col xs={{ size: 1, offset: 1 }}>
                    <div className="logoFooter"></div>
                  </Col>


                  <Col
                    xs={{ size: 2, offset: 5 }}
                    sm={{ size: 2, offset: 8 }}
                    md={{ size: 1, offset: 9 }}

                  >
                    <Row>
                      <Col
                        xs={{ size: 6 }}>
                        <div className="facebookFooter">
                          <a href="https://www.facebook.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>

                        </div>
                      </Col>
                      <Col
                        xs={{ size: 6 }}>
                        <div className="instagramFooter">
                          <a href="https://www.instagram.com/huttetravel/" rel="noopener noreferrer" target="_blank"> </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row id="footerText">
                  <Col xs="12" sm={{ size: 10, offset: 1 }}>
                    <span> © Hütte Travel 2022 · All rights reserved</span>
                    <a href="/privacypolicy.html" rel="noopener noreferrer" > · Privacy Policy</a>
                    <a href="mailto:Hello@hutte.travel">· Say Hello</a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div >
    );
  }
}
