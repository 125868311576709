import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';

import '../css/NavMenu.css'
export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

  }


  render() {
    return (
      <div id="headerImage_xl">
        <Container fluid >
          <header>
            <Row id="headerIconRow">
              <Col xs={{ size: 2, offset: 1 }} md={{ size: 1, offset: 1 }}>
                <div className="logo"></div>
              </Col>

              <Col
                xs={{ size: 4, offset: 5 }}
                sm={{ size: 6, offset: 3 }}
                md={{ size: 5, offset: 5 }}
                lg={{ size: 4, offset: 6 }}
                xl={{ size: 3, offset: 7 }}
              >
                <Row>
                  <Col
                    xs={{ size: 4 }}
                    sm={{ size: 2 }}
                    md={{ size: 2 }}
                    lg={{ size: 2 }}>
                    <div className="facebook">
                      <a href="/#"> </a>

                    </div>
                  </Col>
                  <Col
                    xs={{ size: 4 }}
                    sm={{ size: 2 }}
                    md={{ size: 2 }}
                    lg={{ size: 2 }}>
                    <div className="instagram">
                      <a href="/#"> </a>
                    </div>
                  </Col>
                  <Col
                    sm="4"
                  >
                    <div id="btnSignUp">
                      SIGN UP
                    </div>
                  </Col>
                </Row>
              </Col>


            </Row>
            <Row>
              <Col md={{ size: 8, offset: 2 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <div id="headerText">
                  <p>Your adventure</p>
                  <p>starts with Hütte</p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={{ size: 4, offset: 2 }}>
                <div id="headerText_button">
                  <span>SIGN UP TO HÜTTE</span>
                </div>
              </Col>
            </Row>

          </header>
        </Container>
      </div>
    );
  }
}
