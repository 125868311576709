import React, { Component } from 'react';
import { Row, Container, Col} from 'reactstrap';
import { SignUp } from './SignUp';
import { HashLink as Link } from "react-router-hash-link";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-251143020-1');


export class Host extends Component {
  componentDidMount() {
    document.title = "Become a Host with Hütte"
    ReactGA.pageview(window.location.pathname);
  }


  render() {
    return (
      <div id="hostPage">
        <div className="headerImage_xl" id="hostHeader">
          <Container fluid >
            <header>
              <Row id="headerIconRow">
                <Col xs={{ size: 2, offset: 1 }} md={{ size: 1, offset: 1 }}>
                  <div className="logo"></div>
                </Col>
                <Col id="socialMediaCol"
                  xs={{ size: 4, offset: 5 }}
                  sm={{ size: 6, offset: 3 }}
                  md={{ size: 5, offset: 5 }}
                  lg={{ size: 4, offset: 6 }}
                  xl={{ size: 3, offset: 7 }}
                >
                  <Row>
                    <Col
                      xs={{ size: 4 }}
                      sm={{ size: 2 }}
                      md={{ size: 2 }}
                      lg={{ size: 2 }}>
                      <div className="facebook">
                        <a href="https://www.facebook.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>
                      </div>
                    </Col>
                    <Col
                      xs={{ size: 4 }}
                      sm={{ size: 2 }}
                      md={{ size: 2 }}
                      lg={{ size: 2 }}>
                      <div className="instagram">
                        <a href="https://www.instagram.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>
                      </div>
                    </Col>
                    <Col
                      sm="4"
                    >

                      <Link smooth to="/hostpage#signUpJump">
                        <div id="btnSignUp">
                          SIGN UP
                      </div>
                      </Link>


                    </Col>
                  </Row>
                </Col>


              </Row>
              <Row>
                <Col md={{ size: 10, offset: 1 }} xl={{ size: 5, offset: 2 }} sm={{ size: 10, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                  <div id="hostheaderText">
                    <span id="hostHeaderTextLarge">Come </span><span id="hostHeaderTextLarge" className="bottomOrange">join us</span>
                    <p>We are Hütte. Avid mountain lovers to the core, we connect quality alpine accommodation with their perfect guests. Summer to winter, we'll hook you up with your ideal patrons - meaning happy holidaymakers who come back year after year!
              </p>
                  </div>
                </Col>
              </Row>

              <Row>
              <Col xs={{size:8, offset:1}} sm={{ size: 6, offset: 1 }}  md={{ size: 5, offset: 1 }}   lg={{ size: 4, offset: 1 }} xl={{ size: 3, offset: 2 }} >
                  <div id="headerText_button">
                    <Link smooth to="/hostpage#findoutMoreJump">
                      <span className="jumptextHost">FIND OUT ABOUT HÜTTE</span>
                    </Link>
                  </div>


                </Col>
              </Row>

            </header>
          </Container>
        </div>



        <Container fluid>

          <div className="homeBodyHost">
            <Row>



              <Col md={{ size: 4, offset: 1 }} lg={{ size: 3, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <h1>Freedom from the stress of rentals </h1>
                <p>
                  Think of us as your digital front desk. Ding! We do all the hard work, so you can kick back, relax and enjoy all the benefits of being a host, minus the faff and hassle.  </p>
                <div id="informationTitleButton">
                  <Link smooth to="/hostpage#findoutMoreJump">
                    LEARN MORE
                      </Link>
                </div>
              </Col>
              <Col md={{ size: 5, offset: 1 }} lg={{ size: 6, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <div id="BenefitOneImage"></div></Col>
            </Row>


            <Row className="BenefitPadding">

              <Col md={{ size: 5, offset: 1 }} lg={{ size: 6, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <div id="BenefitTwoImage"></div></Col>
              <Col md={{ size: 4, offset: 1 }} lg={{ size: 3, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <h1 id="BenefitTwoText">Flexibility
          </h1>
                <p>
                  Planning to spend a few weeks holidaying in your beautiful mountain abode? No problem. Whatever the season, advertise when it suits you best.
            </p>
                <div id="informationTitleButton">
                  <Link smooth to="/hostpage#signUpJump">
                    SIGN UP
                      </Link>
                </div>
              </Col>
            </Row>

            <Row className="BenefitPadding">
              <Col md={{ size: 4, offset: 1 }} lg={{ size: 3, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <h1>Showcasing in style
          </h1>
                <p>
                  Your Listings can combine photography with written descriptions, virtual tours and a user profile where guests can learn a bit about their hosts.
            </p>
                <div id="informationTitleButton">
                  <Link smooth to="/hostpage#findoutMoreJump">
                    FIND OUT MORE
                      </Link>
                </div>
              </Col>
              <Col md={{ size: 5, offset: 1 }} lg={{ size: 6, offset: 1 }} xs={{ size: 10, offset: 1 }}>
                <div id="BenefitThreeImage"></div></Col>
            </Row>



            <Row id="middleSignupRowHost">
              <Col xs="12" id="middleSignupHost">
                <Row id="hostSignup">
                  <Col xs="12" xl={{ size: 3, offset: 2 }} md={{ size: 4, offset: 1 }}>
                    <h1>
                      <div id="signUpJump">
                        <span id="signUpDiv" className="bottomOrange">
                          Sign up  </span><span> for updates</span>
                      </div>
                    </h1>
                    <p id="bottomtext">
                      We provide a simple platform to help plan memorable holidays, minus the hassle. Join our growing community! Be part of a new wave in mountain hospitality!
                </p>
                  </Col>
                  <Col xs="12" xl={{ size: 4, offset: 1 }} md={{ size: 4, offset: 1 }} >
                    <div className="hostSignUp">
                      <SignUp groupType="Host" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div id="furtherBenefits">
              <Row >
                <Col md={{ size: 10, offset: 1 }}  >
                  <Row>
                    <Col xs="12">
                      <h1> Further Benefits</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div id="benefitFiller"> </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={{ size: 4 }} xs={{ size: 12 }} >
                      <div className="benefitCircle"></div>
                      <h3>Digital detox</h3>
                      <p>
                        Sick of handling myriad booking sites? Hütte will also manage your integration with separate booking platforms.
                    </p>

                    </Col>

                    <Col lg={{ size: 4 }} xs={{ size: 12 }} >
                      <div className="benefitCircle"></div>
                      <h3>Reduce email load</h3>
                      <p>
                        With instant bookings, trails of emails are a thing of the past. Say hello to more free time!
                    </p>
                    </Col>

                    <Col lg={{ size: 4 }} xs={{ size: 12 }} >
                      <div className="benefitCircle"></div>
                      <h3>All Payments managed</h3>
                      <p>
                        This means no more faffing for you; simply watch the money roll in - stress-free! Our team will ensure payments are received.
                    </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row id="bottomSignupRow">
              <Col xs="12" md="6" id="bottomSignupHost">
                <Row>
                  <Col xs="12" lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }}>
                    <h1>
                      <div id="findoutMoreJump">
                        <span id="signUpDiv" className="bottomOrange">
                          Sign up  </span><span> to find out more</span>
                      </div>
                    </h1>
                    <p id="bottomtext">
                      Sign up to register your interest. One of our dedicated team members will be in touch with all the details.
                </p>
                    <div className="hostSignUp">
                      <div className="bottomsignupdiv">
                      <SignUp groupType="Host" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs="12" md="6" id="bottomPictureHost">
              </Col>
            </Row>

            <Row id="footerHost">
              <Col>
                <Row>

                  <Col xs={{ size: 1, offset: 1 }}>
                    <div className="logoFooter"></div>
                  </Col>


                  <Col
                    xs={{ size: 2, offset: 5 }}
                    sm={{ size: 2, offset: 8 }}
                    md={{ size: 1, offset: 9 }}

                  >
                    <Row>
                      <Col
                        xs={{ size: 6 }}>
                        <div className="facebookFooter">
                          <a href="https://www.facebook.com/HutteTravel" rel="noopener noreferrer" target="_blank"> </a>

                        </div>
                      </Col>
                      <Col
                        xs={{ size: 6 }}>
                        <div className="instagramFooter">
                          <a href="https://www.instagram.com/huttetravel" rel="noopener noreferrer" target="_blank"> </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row id="footerText">
                  <Col xs="12" sm={{ size: 10, offset: 1 }}>
                    <span> © Hütte Travel 2022 · All rights reserved</span>
                    <a href="/privacypolicy.html" rel="noopener noreferrer"> · Privacy Policy</a>
                    <a href="mailto:Hello@hutte.travel">· Say Hello</a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div >
    );
  }
}
