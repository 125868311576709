import React from "react"
import jsonp from "jsonp"
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class Mailchimp extends React.Component {
    state = {
        email: null,
        fullName: null
    };

    handleSubmit(evt) {
        evt.preventDefault();

        var action = this.props.action;

        var group = "";

        if (this.state.fullName != null) {
            var firstName = this.state.fullName.split(' ').slice(0, -1).join(' ');
            var lastName = this.state.fullName.split(' ').slice(-1).join(' ');

            if (firstName === '') {
                firstName = lastName;
                lastName = "";
            }

            if (this.props.groupType === "Host") {
                group = "&group[80615][1]=true"
            }
            else {
                group = "&group[80615][2]=true"

            }


            const values = "EMAIL=" + this.state.email + "&FNAME=" + firstName + "&LNAME=" + lastName + group
            const path = `${action}&${values}`;
            const url = path.replace('/post?', '/post-json?');
            const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/;
            const email = this.state.email;
            (!regex.test(email)) ? this.setState({ status: "empty" }) : this.sendData(url);
        }
        else {
            this.setState({ status: "empty" })
        }

    };

    sendData(url) {
        this.setState({ status: "sending" });
        jsonp(url, { param: "c" }, (err, data) => {
            if (data.msg.includes("already subscribed")) {
                this.setState({ status: 'duplicate' });
            } else if (err) {
                this.setState({ status: 'error' });
            } else if (data.result !== 'success') {
                this.setState({ status: 'error' });
            } else {
                this.setState({ status: 'success' });
            };
        });
    }

    render() {
        const {styles, buttonClassName } = this.props;
        const messages = {
            ...Mailchimp.defaultProps.messages,
            ...this.props.messages
        }
        const { status } = this.state;
        return (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Row>
                    <Col xs="12">
                        <input type="email" placeholder="Your email" className="fullInput" key="email" onChange={({ target }) => this.setState({ email: target.value })} defaultValue={this.state.email}></input>
                    </Col>
                    <Col xs="12">
                        <input type="text" placeholder="Your name" className="fullInput" key="name" onChange={({ target }) => this.setState({ fullName: target.value })} defaultValue={this.state.fullName}></input>
                    </Col>

                    <Col xs="12">
                        <button id="signUpbutton"
                            disabled={status === "sending" || status === "success"}
                            type="submit"
                            className={buttonClassName}>{this.props.buttonLabel}</button>
                    </Col>
                </Row>


                <div className='msg-alert'>

                    {status === "success" && <p style={styles.successMsg}>{messages.success}</p>}
                    {status === "duplicate" && <p style={styles.duplicateMsg}>{messages.duplicate}</p>}
                    {status === "empty" && <p style={styles.errorMsg}>{messages.empty}</p>}
                    {status === "error" && <p style={styles.errorMsg}>{messages.error}</p>}
                </div>
            </form >
        );
    }
}

Mailchimp.defaultProps = {
    messages: {
        sending: "Sending...",
        success: "Thank you for subscribing!",
        error: "An unexpected internal error has occurred.",
        empty: "Please enter required information",
        duplicate: "Too many subscribe attempts for this email address"
    },
    buttonClassName: "",
    styles: {
        sendingMsg: {
            color: "#0652DD"
        },
        successMsg: {
            color: "#FFFFFF"
        },
        duplicateMsg: {
            color: "#EE5A24"
        },
        errorMsg: {
            color: "#ED4C67"
        }
    }
};

Mailchimp.propTypes = {
    action: PropTypes.string,
    messages: PropTypes.object,
    fields: PropTypes.array,
    styles: PropTypes.object,
    className: PropTypes.string,
    buttonClassName: PropTypes.string
};

export default Mailchimp;