import React, { Component } from 'react';
import Mailchimp from './Mailchimp';
export class SignUp extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="signUp">
        <Mailchimp
          action='https://hutte.us7.list-manage.com/subscribe/post?u=f2537750b8cff16491b87ae20&amp;id=f3f1abfa18' buttonLabel="SEND" groupType={this.props.groupType}
        />
      </div>
    );
  }
}
